import React from "react";
import { Link, navigate } from "gatsby";
import { isLoggedIn, logout } from "../services/auth.mjs";
import { Key, LogOut } from "react-feather";
import logo from "../images/logo.min.svg";
import logodark from "../images/darkthemelogo.svg";
import ThemeSwitcher from "../theme-switcher";

import { HelpCircle } from "react-feather";

export default function NavBar({ location }) {
  const logOutHouse = () => {
    window.localStorage.removeItem("session");
    window.localStorage.removeItem("account");
    window.localStorage.removeItem("access");
  };

  const isCondoResourcesPage = location.pathname === "/app/resources/";

  return (
    <header className="sticky left-0 top-0 z-30 h-16 w-full bg-white shadow-md dark:bg-dark-navbar md:h-20">
      <div className="flex h-full items-center justify-between px-6 py-4 lg:px-8 lg:py-6 xl:px-4">
        <Link to="/">
          <span className="sr-only">Opasti</span>
          <img
            title="Etusivulle"
            className="fade-in block h-9 dark:hidden"
            alt=""
            src={logo}
          />
          <img
            title="Etusivulle"
            className="fade-in sticky hidden h-9 object-contain dark:block"
            alt=""
            src={logodark}
          />
        </Link>
        {/* <> //Support
          <Link
            to="/app/support"
            className="flex space-x-1 hover:text-gray-500 hover:no-underline"
          >
            <button className="flex flex-row justify-between float-right px-0 m-0">
              <HelpCircle className="h-5 pt-1" />
              Tuki
            </button>
          </Link>
        </> */}

        {isLoggedIn() && (
          <nav className="flex items-center space-x-6">
            <Link to="/app/handle">Resurssit</Link>
            <Link to="/app/map">Kartta</Link>
          </nav>
        )}

        <nav className="flex items-center gap-2 space-x-4">
          {isLoggedIn() ? (
            <>
              <Link to="/app/profile" className="hover:no-underline">
                Asetukset
              </Link>

              {!isCondoResourcesPage && (
                <a
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    logout(() => navigate("/app/access"));
                  }}
                >
                  <LogOut className="h-5 w-5 sm:inline" />
                </a>
              )}
            </>
          ) : (
            !isCondoResourcesPage && (
              <Link
                to="/app/access/"
                className="flex items-center space-x-1 hover:text-gray-500 hover:no-underline"
              >
                <button
                  title="Kirjaudu sisään"
                  className="btn -primary m-0 flex w-32 flex-row justify-between px-0 pl-4 pr-6"
                >
                  <Key className="h-5" />
                  Kirjaudu
                </button>
              </Link>
            )
          )}

          {isCondoResourcesPage && <ThemeSwitcher />}

          {isCondoResourcesPage && (
            <a
              href="/"
              title="Kirjaudu ulos"
              onClick={() => {
                logOutHouse(() => navigate("/app/access"));
              }}
            >
              <LogOut className="h-7 w-7 sm:inline" />
            </a>
          )}
        </nav>
      </div>
    </header>
  );
}
