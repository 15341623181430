import React from "react";
import NavBar from "./nav-bar";
import Footer from "./footer";
import favicon from "../images/favicon.png";

const Layout = ({ children, location }) => {
  return (
    <>
      <NavBar location={location} />
      <div className="flex min-h-screen flex-col font-medium text-gray-900">
        <main id="fade-in-element" className="flex flex-grow flex-col bg-white">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

export const Head = () => {
  <>
    <title>Opasti</title>
    <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
    <meta
      name="description"
      content="Taloyhtiöden infonäytöt ovat helppo ja nopea tapa jakaa tietoa. Opastamassa SkyLED Finland Oy."
    />
  </>;
};

export default Layout;
