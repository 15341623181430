import { Appwrite } from "appwrite";

// console.log("teams url");
// console.log(process.env.REACT_APP_APPWRITE_ENDPOINT);

export const appwrite = new Appwrite()
  .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
  .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

export const logs = process.env.REACT_APP_LOGS || false;

export const timestampToDateString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString("fi-FI");
};
