import React from "react";
import { Link } from "gatsby";

import logo from "../images/logo.min.svg";
import logodark from "../images/darkthemelogo.svg";
//import skyledQR  from '../images/skyledQR.svg'

const Footer = () => (
  <footer className="sh-custom-light relative z-50 bg-grey-light dark:bg-darker">
    <div className="grid px-6 pb-4 pt-8 md:auto-rows-min md:grid-cols-2">
      <div className="mb-4 flex flex-col md:mb-10 md:items-start">
        <img
          src={logo}
          alt="opasti logo"
          className="mb-6 block h-8 dark:hidden md:h-10"
        />
        <img
          src={logodark}
          alt="opasti logo"
          className="mb-6 hidden h-8 dark:block md:h-10"
        />
        <ul className="menu">
          <li>
            <Link title="Lisätietoja" to="/app/housing-coops">
              Taloyhtiöille
            </Link>
          </li>
          <li>
            <Link title="Lisätietoja" to="/app/marketers">
              Mainostajille
            </Link>
          </li>
        </ul>
      </div>
      <div className="mb-4 flex flex-col justify-center gap-2 text-center md:flex-row md:justify-end md:gap-8 md:text-left">
        <div>
          <p className="dark:text-white">Asiakaspalvelu</p>
          <p className="text-sm dark:text-white">asiakaspalvelu@skyled.fi</p>
        </div>
        <div>
          <p className="dark:text-white">Myynti</p>
          <p className="text-sm dark:text-white">myynti@skyled.fi</p>
          <p className="text-sm dark:text-white">puh. 010-338 4441</p>
        </div>
      </div>
      <div className="text-center dark:text-white md:text-left">
        <p>
          Opastamassa{" "}
          <a
            title="www.skyled.fi"
            href="https://www.skyled.fi"
            className="link-style"
            style={{ color: "#6495ED" }}
          >
            SkyLED Finland Oy
          </a>
        </p>
        <p className="text-sm">&copy; Opasti</p>
      </div>
    </div>
  </footer>
);

export default Footer;
