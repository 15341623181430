import React, { useState, useEffect } from "react";
import SunIcon from "./images/sun.svg";
import MoonIcon from "./images/moon.svg";

const ThemeSwitcher = () => {
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== "undefined") {
      const isDarkMode = localStorage.getItem("darkMode") === "true";
      return isDarkMode;
    }
    return false;
  });

  useEffect(() => {
    const access = localStorage.getItem("access");
    if (!access) return;
    document.documentElement.classList.toggle("dark", darkMode);
    if (typeof window !== "undefined") {
      localStorage.setItem("darkMode", darkMode);
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <button
      title="Tumma/Vaalea tila"
      onClick={toggleDarkMode}
      className={`w-20 items-center justify-center rounded-full px-4 py-2 text-center align-middle ${
        darkMode
          ? "bg-gradient-to-r from-blue-400 to-blue-300"
          : "bg-gradient-to-r from-sky to-sky-dark"
      } transition-colors duration-200`}
    >
      {darkMode ? (
        <img src={SunIcon} alt="Dark Mode" className="ml-3 h-6 w-6" />
      ) : (
        <img src={MoonIcon} alt="Light Mode" className="ml-3 h-6 w-6" />
      )}
    </button>
  );
};

export default ThemeSwitcher;
