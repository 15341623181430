import { navigate } from "gatsby";
import { appwrite } from "../utils.mjs";

const DEBUG_MODE = false;
//Change to true to access profile locally

export const isBrowser = () => typeof window !== "undefined";

export const getSession = () =>
  isBrowser() &&
  window.localStorage.getItem("session") &&
  JSON.parse(window.localStorage.getItem("session")).expire >
    ~~(Date.now() / 1000)
    ? JSON.parse(window.localStorage.getItem("session"))
    : false;

const setSession = (user) =>
  window.localStorage.setItem("session", JSON.stringify(user));

const accountReminder = (account) => {
  // Check does user need verification email
  // TODO: Show notification for user
  if (account.emailVerification === false) {
    // handleVerification({})
  }
};

const setAccount = (account) => {
  window.localStorage.setItem(
    "account",
    JSON.stringify({
      ...account,
      _revised: ~~(Date.now() / 1000),
    }),
  );
  accountReminder(account);
};

const accountJustUpdated = () =>
  !(
    window.localStorage.getItem("account") &&
    JSON.parse(window.localStorage.getItem("account"))._revised < // <- NOTICE
      ~~(Date.now() / 1000) - 5 * 60
  );

export const getAccount = () => {
  if (isBrowser()) {
    // If get account is fresh
    if (!accountJustUpdated()) {
      //       if (logs) console.log("Updating account info.");
      const promise = appwrite.account.get();
      promise.then(
        function (response) {
          //           if (logs) console.log("Get account success: ", response);
          if (!accountJustUpdated()) setAccount(response);
        },
        function (error) {
          //           if (logs) console.log("Get account error: ", error);
        },
      );
    }

    return JSON.parse(window.localStorage.getItem("account"));
  }
};

export const handleLogin = ({ email, password, goto = "/app/profile" }) => {
  if (DEBUG_MODE) {
    // Simulate login for debug mode
    setSession({ user: email, expire: Date.now() + 3600 }); // Set session to expire in 1 hour
    navigate(goto);
    return;
  } else {
    return appwrite.account.createSession(email, password).then(
      function (session) {
        //         if (logs) console.log("Get session success: ", session);
        return appwrite.account.get().then(
          function (account) {
            //             if (logs) console.log("Get account success: ", account);
            setAccount(account);
            setSession(session);
            navigate(goto);
          },
          function (error) {
            //             if (logs) console.log("Get account error: ", error);
          },
        );
      },
      function (error) {
        //         if (logs) console.log("Get session error: ", error);
      },
    );
  }
};

export const isLoggedIn = () => {
  const user = getSession();
  return !!user;
};

export const logout = (callback) => {
  if (DEBUG_MODE) {
    // Simulate logout for debug mode
    window.localStorage.removeItem("session");
    window.localStorage.removeItem("account");
    callback();
    return;
  } else {
    const promise = appwrite.account.deleteSession(getSession().$id);
    promise.then(
      function (response) {
        //         if (logs) console.log(response);
      },
      function (error) {
        //         if (logs) console.log(error);
      },
    );
    window.localStorage.removeItem("session");
    window.localStorage.removeItem("account");
    window.localStorage.removeItem("appwrite_jwt");
    window.localStorage.removeItem("cart");
    callback();
  }
};

export const handleRegistration = ({
  email,
  password,
  name = "",
  goto = "/app/profile",
}) => {
  if (DEBUG_MODE) {
    // Simulate registration for debug mode
    // Automatically log in for user
    handleLogin({ email, password, goto });
    return;
  } else {
    const promise = appwrite.account.create(email, password, name);
    promise.then(
      function (response) {
        //         if (logs) console.log("Account creation success: ", response);
        // Automatically log in for user
        handleLogin({ email, password, goto });
      },
      function (error) {
        //         if (logs) console.log("Account creation error: ", error);
      },
    );
  }
};
